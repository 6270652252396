<template>
    <div>
        <bread-crumbs :items="items" />
        <v-row style="direction:rtl">
            <v-col cols="12" md="2" sm="4" offset-md="10">
                <v-btn
                block
                v-b-toggle.add_marketing
                style="width:25%;background: green !important;color:#FFF !important;margin-top:23px;"
                >اضافة حملة</v-btn>
            </v-col>
            <v-col cols="12" style="direction: rtl;">
                <v-data-table
                    :headers="headers"
                    :items="tablerows"
                    width="100%"
                    id="MainTable"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{ row.item.counter }}</td>
                            <td>{{ row.item.title }}</td>
                            <td nowrap>{{ row.item.types }}</td>
                            <td nowrap>{{ row.item.mobilescount }}</td>
                            <td>{{ row.item.sent_at }}</td>
                            <td>{{ row.item.created_date }}</td>
                            <td nowrap>{{ row.item.created_by }}</td>
                            <td>{{ row.item.statustext }}</td>
                            <td nowrap="" style="display:none;">
                            <i class="fas fa-pencil-alt ma-1" style="cursor:pointer;font-size:1.2rem;color:#222" @click="ViewItem(row.item)"></i>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <addCampain />
    </div>
</template>

<script>
import addCampain from '@/components/addCampain.vue'
import axios from 'axios';
import breadCrumbs from '@/components/breadCrumbs.vue';
export default {
  components: { breadCrumbs },
    data: () => ({
        items:[
        // {
        //   text: this.$store.state.hometitle,
        //   disabled: false,
        //   href: '/home',
        // },
        {
          text: 'الحملات الاعلانية',
          disabled: true,
          href: '/',
        },
      ],
        headers: [
            {
                text: '',
                align: 'center',
                filterable: false,
                value: 'counter',
            },
            {
                text: 'عنوان الحملة',
                align: 'center',
                filterable: false,
                value: 'title',
            },
            {
                text: 'نوع الحملة',
                align: 'center',
                filterable: false,
                value: 'types',
            },
            {
                text: 'عدد الارقام',
                align: 'center',
                filterable: false,
                value: 'mobilescount',
            },
            {
                text: 'تاريخ الارسال',
                align: 'center',
                filterable: false,
                value: 'sent_at',
            },
            {
                text: 'تاريخ الاضافة',
                align: 'center',
                filterable: false,
                value: 'created_date',
            },
            {
                text: 'بواسطة',
                align: 'center',
                filterable: false,
                value: 'created_by',
            },
            {
                text: 'الحالة',
                align: 'center',
                filterable: false,
                value: 'statustext',
            },
            // {
            //     text:'action',
            //     align: 'center',
            //     filterable: false,
            // }
        ],
        tablerows: [

        ]
    }),
    methods: {
        getCamps() {
            const post = new FormData();
            post.append("type","getCampaigns");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[search]",'all');
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    // console.log(res.results.data.results);
                    this.tablerows = res.results.data.results;
                }
            )
        }
    },
    created() {
        this.getCamps();
        setInterval(()=>{this.getCamps()},3000)
        //// console.log(this.tablerows);
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
}
</script>